<template>
	<div class="azimuth-selector">
		<div class="circle">
			<div
				v-for="(direction, index) in directions"
				:key="index"
				:class="['direction', { active: value === direction.value }]"
				:style="getStyle(index)"
				@click="selectDirection(direction.value)"
			>
				<span class="label" :style="getLabelStyle(index)">{{ direction.label }}</span>
			</div>
		</div>
		<button class="close-btn" @click="closeSelector">關閉</button>
	</div>
</template>

<script>

export default {
	name: "AzimuthSelector",
	props: {
		value: {
			type: String
		},
	},
	data() {
		return {
			directions: [
				{ label: "東", value: "東" },
				{ label: "東南東", value: "東南東" },
				{ label: "東南", value: "東南" },
				{ label: "南南東", value: "南南東" },
				{ label: "南", value: "南" },
				{ label: "南南西", value: "南南西" },
				{ label: "西南", value: "西南" },
				{ label: "西南西", value: "西南西" },
				{ label: "西", value: "西" },
				{ label: "西北西", value: "西北西" },
				{ label: "西北", value: "西北" },
				{ label: "北北西", value: "北北西" },
                { label: "北", value: "北" },
				{ label: "北北東", value: "北北東" },
				{ label: "東北", value: "東北" },
				{ label: "東北東", value: "東北東" },
			],
		};
	},
	methods: {
		selectDirection(direction) {
			this.$emit("inputcallback", direction); // 回傳選擇的方位值
			//this.closeSelector(); // 關閉組件
		},
		getStyle(index) {
			const angle = (index / 16) * 360;
			return {
				transform: `rotate(${angle}deg) translate(90px) rotate(-${angle}deg)`,
				position: "absolute",
				top: "50%",
				left: "50%",
				width: "50px",
				height: "50px",
				margin: "-25px", /* 讓圓形置中 */
			};
		},
		getLabelStyle(index) {
			const angle = (index / 16) * 360;
			return {
				transform: `rotate(${angle}deg)`,
			};
		},
		closeSelector() {
			this.$emit("close");
		},
	},
};
</script>

<style scoped>
.azimuth-selector {
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
	width: 350px;
	height: 300px;
	flex-direction: column;
}
.circle {
	position: relative;
	width: 200px;
	height: 200px;
	border: 1px solid #ccc;
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
}
.direction {
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: #f0f0f0;
	border-radius: 50%;
	cursor: pointer;
	transition: background-color 0.3s, transform 0.2s;
}
.direction.active {
	background-color: #ff5722;
	color: white;
	font-weight: bold;
	transform: scale(1.2);
}
.label {
	position: absolute;
	pointer-events: none; /* 讓文字不影響點擊 */
}
.close-btn {
	margin-top: 20px;
	padding: 5px 10px;
	background-color: #ff5722;
	color: white;
	border: none;
	border-radius: 5px;
	cursor: pointer;
}
.close-btn:hover {
	background-color: #e64a19;
}
</style>
